

default-color() {
	color: #6b6b71;
}

title-color() {
	color: #4d495b;
}

link-color() {
	color: #fe9200;
}

default-font() {
	font: 15px / 20px 'Roboto', Arial, Helvetica, sans-serif;
	@media (max-width: 1023px) {
		font-size: 15px;
		line-height: 24px;
	}
}

clear-list() {
	margin: 0;
	list-style: none;
	padding: 0;
}

clear-font() {
	font-size: 0;
	line-height: 0;
	letter-spacing: 0;
}

clear-inline-block() {
	clear-font();
	> * {
		default-font();
		display: inline-block;
		vertical-align: middle;
	}
}

justify-block() {
	clear-inline-block();
	text-align: justify;
	&:after {
		content: "";
		display: inline-block;
		height: 0;
		vertical-align: middle;
		visibility: hidden;
		width: 100%;
	}
}

vertical-block() {
	clear-inline-block();
	&:after {
		content: "";
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		visibility: hidden;
		width: 0;
	}
}

add-transition($type = all, $ease = 0.4s, $wait = 0s) {
	transition: $type $ease $wait ease-in-out;
}


@require 'functions'

@import '../css/*.css'

html,
body {
	height: 100%;
	width: 100%;
}

body {
	default-color();
	default-font();
	add-transition(all, 1s);
	height: 100%;
	min-height: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	@media (max-width: 1200px) {
		overflow-y: auto;
	}
	&.normal-scroll {
		transition: none;
	}
	&.popup {
		overflow: hidden;
	}
}

a {
	add-transition();
	link-color();
	&:hover {
		text-decoration: none;
	}
}

.bold {
	color: #000000;
	font-weight: bold;
}

.xs {
	&-break {
		@media (max-width: 767px) {
			display: block;
		}
	}
	&-visible {
		display: none !important;
		@media (max-width: 767px) {
			display: block !important;
		}
	}
}

.xl-visible {
	@media (max-width: 767px) {
		display: none !important;
	}
}

.beauty {
	&-link {
		display: inline-block;
		position: relative;
		text-decoration: none;
		&:after {
			add-transition();
			border-bottom: 1px solid #fe9200;
			bottom: -2px;
			content: '';
			display: block;
			left: 0;
			margin: auto;
			right: 0;
			position: absolute;
			width: 0;
		}
		&:hover {
			&:after {
				width: 100%;
			}
		}
	}
	&-file {
		link-color();
		display: block;
		font-size: 17px;
		overflow: hidden;
		padding: 0 0 2px;
		text-decoration: none;
		&__text {
			position: relative;
			{"^[-1]"}:hover & {
					 svg {
						 .stroke {
							 stroke: #fe9200;
						 }
					 }
					 span {
						 &:after {
							 width: 100%;
						 }
					 }
				 }
			svg {
				left: -32px;
				height: 20px;
				position: absolute;
				top: 0;
				.stroke {
					add-transition();
				}
			}
			span {
				position: relative;
				&:after {
					add-transition();
					border-bottom: 1px solid #fe9200;
					bottom: -2px;
					content: '';
					display: block;
					left: 0;
					margin: auto;
					right: 0;
					position: absolute;
					width: 0;
				}
			}
		}
		&__input {
			top: -10px;
			right: -40px;
			z-index: 2;
			position: absolute;
			cursor: pointer;
			opacity: 0;
			font-size: 50px;
		}
	}
	&-list {
		clear-list();
		li {
			padding: 3px 0 0 20px;
			position: relative;
			&:before {
				background: #fe9200;
				border-radius: 50%;
				content: "";
				height: 3px;
				left: 0;
				position: absolute;
				top: 5px;
				width: 3px;
			}
			&:first-child {
				padding-top: 0;
			}
		}
	}
}

form {
	position: relative;
	textarea
	input[type="text"]
	input[type="email"] {
		add-transition();
		border: 2px solid #b2b2b7;
		box-sizing: border-box;
		display: block;
		font-size: 15px;
		height: 47px;
		line-height: 17px;
		margin: 0 0 24px;
		outline: none;
		padding: 15px;
		width: 100%;
		@media (max-width: 767px) {
			text-align: center;
		}
		&:focus {
			border-color: #fe9200;
		}
	}
	textarea {
		height: 132px;
		resize: none;
	}
	input[name="username"] {
		clear-font();
		background: none;
		border: none;
		color: transparent;
		display: inline;
		height: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		width: 0;
	}
	&.sending {
		&:after {
			background: rgba(255,255,255,0.5);
			bottom: 0;
			content: "";
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 5;
		}
	}
}

p {
	margin: 15px 0;
}

h1
h2
h3
h4 {
	title-color();
	letter-spacing: 0.03em;
	font-weight: 500;
	text-transform: uppercase;
}

h1 {
	font-weight: 900;
}

.button {
	add-transition(color, 0.4s, 0.1s);
	background: #fe9200;
	border: 3px solid #fe9200;
	box-sizing: border-box;
	color: #ffffff;
	cursor: pointer;
	display: inline-block;
	font-size: 15px;
	font-weight: bold;
	height: 50px;
	letter-spacing: 0.05em;
	line-height: 20px;
	outline: none;
	padding: 12px 17px;
	position: relative;
	text-decoration: none;
	text-transform: uppercase;
	z-index: 1
	@media (min-width: 768px) {
		&:hover {
			title-color();
			&:before {
				transform: scaleY(1);
			}
		}
	}
	&:before {
		add-transition();
		background: #ffffff;
		bottom: 0;
		content: "";
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		transform: scaleY(0);
		transform-origin: top;
		z-index: -1;
	}
}

.table {
	display: table;
	width: 100%;
	@media (max-width: 767px) {
		display: block;
		width: auto;
	}
	&-row {
		display: table-row;
		@media (max-width: 767px) {
			display: block;
			margin: 40px 0 0;
			&:first-child {
				margin: 0;
			}
		}
	}
	&-column {
		display: table-cell;
		vertical-align: top;
		padding-top: 50px;
		width: 50%;
		{"^[0]"}-row:first-child & {
			 padding-top 0;
	 	}
		@media (max-width: 767px) {
			display: block;
			padding: 0;
			width: 100% !important;
		}
	}
}

header {
	background: #ebebeb;
	height: 45px;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 5;
}

.menu {
	vertical-block();
	height: 45px;
	text-align: center;
	@media (max-width: 767px) {
		background: #ebebeb;
		bottom: 0;
		display: none;
		height: auto;
		left: 0;
		overflow-y: auto;
		padding: 0 6%;
		position: fixed;
		right: 0;
		top: 45px;
		&-open {
			overflow: hidden;
		}
		&-open & {
			display: block;
		}
	}
	&-wrap {
		width: 100%;
	}
	&-list {
		clear-list();
		clear-inline-block();
		&__item {
			title-color();
			add-transition(all, 0.3s);
			cursor: pointer;
			font-size: 13px;
			font-weight: bold;
			line-height: 17px;
			letter-spacing: 0.025em;
			margin-left: 50px;
			text-transform: uppercase;
			@media (min-width: 768px) {
				&:first-child {
					margin-left: -30px !important; /* fix center menu */
				}
			}
			@media (max-width: 767px) {
				font-size: 25px;
				font-weight: 500;
				line-height: 40px;
				letter-spacing: 0.03em;
				margin: 40px 0 0;
				text-transform: uppercase;
				width: 100%;
			}
			&:first-child {
				margin: 0;
			}
			&.active
			&:hover {
				link-color();
			}
		}
		&__logo {
			height: 17px;
			width: 110px;
			opacity: 0;
			img {
				display: block;
				height: 100%;
				width: auto;
			}
			.scrolled & {
				opacity: 1;
				transition-delay: 0.4s;
			}
		}
	}
	&-switch {
		cursor: pointer;
		outline: none;
		position: absolute;
		right: 10px;
		top: 10px;
		&__line {
			add-transition(all, 0.3s);
			background-color: #4d495b;
			display: block;
			margin: 4px auto;
			height: 3px;
			width: 30px;
		}
		&.active{
			add-transition(all, 0.3s, 0.6s);
			transform: rotate(45deg);
			& {"^[-1]"}__line {
				&:nth-child(1) {
					transform: translateY(7px);
				}
				&:nth-child(2) {
					width: 0;
				}
				&:nth-child(3) {
					transform: translateY(-7px) rotate(90deg);
				}
				&:nth-child(1)
				&:nth-child(3) {
					transition-delay: 0.3s;
				}
			}
		}
	}
	&-pdf {
		margin: 50px auto 0;
		width: 200px;
	}
}

.logo {
	add-transition(all, 0.4s, 0s);
	background: #ebebeb;
	left: 50%;
	margin: 0 0 0 -36px;
	padding: 10px;
	overflow: hidden;
	position: absolute;
	top: 45px;
	/*FIXME transition-timing-function: cubic-bezier(0.4, 0, 0, 1.64);*/
	width: 72px;
	z-index: -1;
	@media (max-width: 767px) {
		margin-left: -35px;
		top: 0;
		width: 50px;
		.menu-open & {
			display: none !important;
		}
	}
	img {
		display: block;
		margin: -40px 0 0;
		height: auto;
		width: 100%;
		@media (max-width: 767px) {
			margin: 0;
		}
	}
	@media (max-width: 767px) {
		.scrolled & {
			display: none;
			transition: none;
		}
	}
	@media (min-width: 768px) {
		.scrolled & {
			transform: skewX(25deg) scaleY(0) translateY(-70px);
			transform-origin: top;
			transition-delay: 0s;
			/*transition-timing-function: ease-in-out;*/
		}
	}
	&-top {
		display: none;
		height: 17px;
		left: 50%;
		margin: -6.5px 0 0 -55px;
		position: absolute;
		top: 50%;
		width: 110px;
		@media (max-width: 767px) {
			img {
				display: block;
				height: 100%;
				width: auto;
			}
			.scrolled & {
				display: block;
			}
			.menu-open & {
				display: none;
			}
		}
	}
}

section {
	height: 100%;
	width: 100%;
}

.page-block {
	box-sizing: border-box;
	height: 100%;
	overflow: hidden;
	padding: 100px 0 0;
	position: relative;
	z-index: 1;
	@media (max-width: 1200px) {
		height: auto;
		padding-bottom: 50px;
	}
	@media (max-width: 767px) {
		padding: 75px 0 0;
	}
	&__wrap {
		add-transition(top, 0.15s);
		margin: 0 auto;
		max-width: 1100px;
		position: relative;
		@media (max-width: 1200px) {
			max-width: 100%;
			width: 100%;
		}
	}
	&__title {
		margin: 0 0 50px;
		text-align: center;
		text-transform: uppercase;
		@media (max-width: 767px) {
			margin: 0 0 30px;
		}
		h2 {
			add-transition(all, 1s);
			display: inline-block;
			font-size: 60px;
			font-weight: 500;
			letter-spacing: 0.03em;
			line-height: 65px;
			margin: 0;
			padding: 0 0 10px;
			position: relative;
			@media (max-width: 767px) {
				font-size: 25px;
				line-height: 32px;
			}
			@media (min-width: 768px) {
				transform: translateY(100px);
			}
			&:before {
				border-bottom: 3px solid #fe9200;
				bottom: 0;
				content: "";
				left: 0;
				margin: 0 auto;
				position: absolute;
				right: 0;
				width: 80%;
			}
			@media (min-width: 768px) {
				.showed & {
					font-size: 32px;
					line-height: 43px;
					transform: translateY(0);
					transition-delay: 1s;
				}
			}
		}
	}
	&__content {
		@media (min-width: 768px) {
			//add-transition(all, 1s);
			opacity: 0;
			.showed & {
				opacity: 1;
				transition-delay: 2s;
			}
		}
		&-text {
			margin 0 auto 90px;
			max-width: 360px;
			text-align: center;
			@media (max-width: 767px) {
				margin-bottom: 30px;
				padding: 0 6%;
				br {
					display: none;
				}
			}
			.vacancy & {
				margin-bottom: 120px;
				@media (max-width: 767px) {
					margin-bottom: 50px;
				}
			}
			.contacts & {
				margin-bottom: 40px;
				@media (max-width: 767px) {
					br {
						display: block;
					}
				}
			}
			.large {
				font-size: 18px;
				font-weight: bold;
			}
			p.large {
				margin: 25px 0 0;
			}
		}
	}
	&__form {
		background: rgba(255,255,255,0.6) url(img/pattern.png) 50% repeat;
		box-sizing: border-box;
		max-width: 580px;
		padding: 40px 35px;
	}
}

.main {
	background: #ebebeb;
	vertical-block();
	position: relative;
	top: auto;
	text-align: center;
	@media (max-width: 1200px) {
		height: 100% !important;
	}
	@media (max-width: 767px) {
		height: auto !important;
		padding: 45px 0 0;
	}
	&-background {
		bottom: 0;
		left: 0;
		overflow: hidden;
		position: absolute;
		right: 0;
		top: 0;
		z-index: -1;
		@media (max-width: 767px) {
			background: url(img/main.jpg) 50% 0 / cover no-repeat;
			height: 150px;
			margin: 0 0 25px;
			position: static;
			width: 100%;
		}
		&:before {
			add-transition(all, 4s);
			background: url(img/main.jpg) 50% / cover no-repeat;
			bottom: 0;
			content: "";
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			@media (max-width: 767px) {
				display: none;
			}
			@media (min-width: 768px) {
				.loaded & {
					transform: scale(1.1);
				}
			}
		}
	}
	&-content {
		background: url(img/pattern.png) 50% repeat;
		box-sizing: border-box;
		padding: 50px;
		max-width: 580px;
		@media (max-width: 767px) {
			background: none;
			display: block;
			padding: 0 6%;
			width: 100%;
		}
		&__title {
			margin: 0 0 44px;
			padding: 0 0 7px;
			position: relative;
			@media (max-width: 767px) {
				margin: 0 0 30px;
			}
			&:before {
				border-bottom: 3px solid #fe9200;
				bottom: 0;
				content: "";
				left: 0;
				margin: 0 auto;
				position: absolute;
				right: 0;
				width: 110px;
			}
			h1 {
				font-size: 60px;
				line-height: 100%;
				margin: 0;
				@media (max-width: 767px) {
					font-size: 40px;
					font-weight: 500;
				}
			}
			h2 {
				font-size: 32px;
				line-height: 40px;
				margin: 0;
				@media (max-width: 767px) {
					font-size: 15px;
					font-weight: 400;
					line-height: 22px;
				}
			}
		}
		&__text {
			margin: 0 auto 32px;
			max-width: 380px;
			@media (max-width: 767px) {
				br {
					display: none;
				}
			}
		}
		&__button {
			@media (max-width: 767px) {
				display: none;
			}
		}
	}
	&-scroll {
		bottom: 40px;
		height: 42px;
		left: 50%;
		margin: 0 0 0 -14px;
		position: absolute;
		width: 28px;
		@media (max-width: 767px) {
			display: none;
		}
		/*&:hover {
			img {
				transform: scale(0.84);
			}
		}*/
		svg {
			animation: mouseScroll 1.8s linear 4s 4;
			add-transition(all, 0.3s);
			transform-origin: 50% 100%;
			display: block;
			margin: 2px auto 0;
			height: auto;
			width: 25px;
		}
	}
}

.tech
.vacancy
.contacts {
	@media (min-width: 768px) {
		&:before {
			add-transition(all, 1s);
			background: url(img/technology.jpg) 50% / cover no-repeat;
			bottom: 0;
			content: "";
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: -1;
		}
		&:after {
			add-transition(all, 0.3s);
			background: url(img/svg/code.svg) 50% 0 no-repeat;
			bottom: 0;
			content: "";
			left: 0;
			margin: 0 auto;
			height: 280px;
			opacity: 0;
			right: 0;
			position: absolute;
			width: 1180px;
			z-index: -1;
			@media (max-width: 1200px) {
				height: 200px;
				width: 100%;
			}
		}
		&.showed {
			&:before {
				opacity: 0;
				transition-delay: 1s;
			}
			&:after {
				opacity: 1;
				transition-delay: 2s;
			}
		}
	}
	@media (max-width: 1200px) {
		&:after {
			bottom: 50px;
		}
	}
}

.vacancy {
	&:before {
		background-image: url(img/vacancy.jpg);
	}
	&:after {
		background-image: url(img/svg/zavod.svg);
	}
	&-carousel {
		li {
			cursor: pointer;
		}
	}
}

.contacts {
	@media (max-width: 1200px) {
		padding-bottom: 0;
	}
	&:before {
		background-image: url(img/contacts.jpg);
	}
	@media (min-width: 768px) {
		&.showed {
			&:before {
				opacity: 0.5;
			}
		}
	}
	&:after {
		display: none;
	}
}
.swiper {
	&-container {
		&.vacancy-carousel {
			a {
				text-decoration: none;
			}
		}

	}
	&-wrapper {
		add-transition(all, 0.5s);
		clear-list();
		clear-inline-block();
		text-align: center;
		@media (min-width: 768px) {
			margin: -45px 0 0 -45px;
			transform: translateY(50px);
			.showed & {
				transform: translateY(0);
				transition-delay: 2s;
			}
		}
		@media (max-width: 1200px) {
			margin: -30px 0 0 -30px;
		}
		@media (max-width: 767px) {
			margin: 0;
			padding: 0 0 50px;
		}
	}
	&-slide {
		margin: 45px 0 0 45px;
		padding: 0 10px;
		text-align: center;
		vertical-align: top;
		width: 220px;
		@media (max-width: 1200px) {
			margin: 30px 0 0 30px;
			width: 205px;
		}
		@media (max-width: 767px) {
			box-sizing: border-box;
			margin: 0;
			width: 100%;
		}
		&__title {
			title-color();
			font-size: 19px;
			font-weight: bold;
			height: 44px;
			line-height: 22px;
			margin: 0 0 10px !important;
			overflow: hidden;
			.vacancy-carousel & {
				add-transition();
				cursor: pointer;
				&:hover {
					link-color();
				}
			}
		}
		p {
			margin: 0;
		}
	}
	&-pagination {
		height: 10px;
		margin: 20px 0;
		&-bullet {
			background: none;
			border: 2px solid #fe9200;
			box-sizing: border-box;
			height: 10px;
			opacity: 1;
			width: 10px;
			&-active {
				background: #fe9200;
			}
		}
	}
}

.technologies {
	&-list {
		margin: -80px 0 0 -45px;
		@media (max-width: 1200px) {
			margin: -50px 0 0 -30px;
		}
		@media (max-width: 767px) {
			margin: 0;
		}
		&__item {
			add-transition();
			border-radius: 6px;
			padding: 20px 10px 40px;
			@media (max-width: 1200px) {
				margin: 10px 0 0 30px;
				padding: 10px 10px 20px;
			}
			@media (min-width: 768px) {
				&:hover {
					color: #ffffff;
					background: #fe9200;
					.technologies-list__icon {
						&:before {
							width: 150px;
						}
					}
					.star {
						opacity: 1;
						transition-delay: 0.15s;
						&:nth-child(2) {
							transition-delay: 0.25s;
						}
						&:nth-child(3) {
							transition-delay: 0.35s;
						}
						&:nth-child(4) {
							transition-delay: 0.45s;
						}
					}
					.black
					.orange {
						fill: #ffffff;
					}
					svg {
						transform: scale(1);
					}
				}
			}
			@media (max-width: 767px) {
				margin: 0;
			}
		}
		&__icon {
			vertical-block();
			display: inline-block;
			height: 100px;
			margin: 0 0 25px;
			position: relative;
			width: 100%;
			&:before {
				add-transition();
				border-bottom: 2px solid #ffffff;
				bottom: -2px;
				content: "";
				left: 0;
				margin: 0 auto;
				position: absolute;
				right: 0;
				width: 0;
			}
			&-frontend {
				svg {
					margin-left: -5px;
				}
			}
			&-backend {
				svg {
					margin-left: -15px;
					width: 85px;
				}
			}
			svg {
				add-transition();
				height: auto;
				transform: scale(0.9);
				width: 75px;
				path
				rect {
					add-transition(all, 0.3s);
				}
				.star {
					opacity: 0;
				}
				.orange {
					fill: #fe9200;
				}
			}
		}
	}
}

.form-static {
	margin: 0 auto;
	@media (max-width: 767px) {
		background: #ebebeb !important;
		text-align: center;
	}
	&__bottom {
		height: 50px;
		position: relative;
		@media (max-width: 767px) {
			height: auto;
		}
	}
	&__message {
		color: #fe9200;
		display: none;
		font-size: 17px;
		font-weight: bold;
		line-height: 22px;
		margin: 10px 0 0;
	}
	&__file {
		left: 165px;
		position: absolute;
		padding: 0 0 2px 32px;
		top: 15px;
		@media (max-width: 767px) {
			display: inline-block;
			margin: 0 0 20px;
			position: static;
		}
	}
	&__agreement {
		display: block;
		line-height: 24px;
		margin: 0 0 20px;
		padding: 0 0 0 25px;
		position: relative;
		text-align: left;
		input {
			display: inline-block;
			height: 16px;
			left: 0;
			line-height: 20px;
			margin: 0 5px 0 0;
			position: absolute;
			top: 3px;
			width: 16px;
		}
		span {
			link-color();
			cursor: pointer;
			display: inline-block;
			text-decoration: none;
			vertical-align: middle;
			&:hover {
				text-decoration: underline;
			}
			@media (min-width: 768px) {
				position: relative;
				text-decoration: none;
				&:after {
					add-transition();
					border-bottom: 1px solid #fe9200;
					bottom: 0px;
					content: '';
					display: block;
					left: 0;
					margin: auto;
					right: 0;
					position: absolute;
					width: 0;
				}
				&:hover {
					text-decoration: none;
					&:after {
						width: 100%;
					}
				}
			}
		}
	}
	.button {
		@media (max-width: 767px) {
			width: 100%;
		}
	}
}

footer {
	/*add-transition();*/
	background: #ebebeb;
	margin: 80px -2000px 0;
	/*transform: translateY(50px);*/
	@media (max-width: 1200px) {
		margin: 80px 0 0;
	}
	@media (max-width: 767px) {
		margin: 0;
	}
	.footer {
		margin: 0 auto;
		width: 1180px;
		@media (max-width: 1200px) {
			box-sizing: border-box;
			padding: 0 40px;
			width: 100%;
		}
		@media (max-width: 767px) {
			padding: 0;
		}
		&-row {
			color: #b2b2b7;
			font-size: 13px;
			line-height: 25px;
			overflow: hidden;
			padding: 10px 0;
			@media (max-width: 767px) {
				padding: 0;
			}
		}
		&-social {
			float: right;
			text-align: right;
			width: 50%;
			@media (max-width: 767px) {
				background: #ffffff;
				float: none;
				text-align: center;
				padding: 35px 6% 40px;
				width: auto;
			}
			a {
				display: inline-block;
				margin: 0 0 0 15px;
				vertical-align: middle;
				&:first-child {
					margin: 0;
				}
				&:hover {
					svg {
						.back {
							fill: #fe9200;
						}
					}
				}
				svg {
					display: block;
					height: 25px;
					width: 25px;
					@media (max-width: 767px) {
						height: 45px;
						width: 45px;
					}
				}
			}
			span {
				margin: 0 0 0 10px;
				@media (max-width: 767px) {
					display: block;
					margin: 23px 0 0;
				}
			}
		}
		&-copy {
			float: left;
			width: 50%;
			@media (max-width: 767px) {
				float: none;
				padding: 10px 6%;
				text-align: center;
				width: auto;
			}
			.copy {
				display: inline-block;
				font-size: 25px;
				font-weight: bold;
				margin: 0 5px 0 0;
				vertical-align: middle;
			}
		}
	}
	/*.showed & {
		transition-duration: 1s;
		transition-delay: 2s;
		transform: translateY(0);
	}*/
}

.vacancy-popup {
	background: #ffffff;
	bottom: 0;
	display: none;
	left: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 10;
	&__eq {
		display: none;
		&[data-index="1"] {
			display: block;
		}
	}
	&__overlay {
		add-transition();
		height: 100%;
		overflow-y: auto;
		transform: translateY(50px);
	}
	&__layout {
		box-sizing: border-box;
		margin: 0 auto;
		padding: 75px 0;
		position: relative;
		width: 1200px;
		@media (max-width: 1200px) {
			max-width: 462px;
			width: auto;
		}
		@media (max-width: 767px) {
			padding: 45px 0 0;
		}
	}
	&__close {
		cursor: pointer;
		height: 26px;
		position: absolute;
		right: 10px;
		top: 10px;
		width: 26px;
		&:hover {
			&:before {
				animation: closePopup 0.5s linear;
			}
		}
		&:before {
			background: url(img/svg/close.svg) 50% / 100% no-repeat;
			content: "";
			height: 16px;
			left: 5px;
			position: absolute;
			top: 5px;
			width: 16px;
		}
	}
	&__small {
		title-color();
		font-size: 20px;
		line-height: 22px;
		text-align: center;
	}
	&__title {
		add-transition();
		margin: 0 0 45px;
		text-align: center;
		@media (max-width: 767px) {
			margin: 0 0 30px;
		}
		span {
			title-color();
			display: inline-block;
			font-size: 32px;
			font-weight: 500;
			letter-spacing: .03em;
			line-height: 43px;
			padding: 0 0 5px;
			position: relative;
			text-transform: uppercase;
			@media (max-width: 767px) {
				font-size: 25px;
				line-height: 32px;
			}
			&:before {
				border-bottom: 3px solid #fe9200;
				bottom: 0;
				content: "";
				left: 0;
				margin: 0 auto;
				position: absolute;
				right: 0;
				width: 150px;
			}
		}
	}
	&__caption {
		margin: 0 auto;
		max-width: 270px;
		text-align: center;
	}
	&__content {
		overflow: hidden;
		padding: 35px 0 0;
	}
	&__left {
		float: left;
		width: 550px;
		@media (max-width: 1200px) {
			float: none;
			width: 100%;
		}
		@media (max-width: 767px) {
			box-sizing: border-box;
			padding: 0 6%;
		}
		.table-column {
			width: 350px;
			@media (max-width: 1200px) {
				width: 312px;
			}
			&:first-child {
				width: 200px;
				@media (max-width: 1200px) {
					width: 150px;
				}
				@media (max-width: 767px) {
					span.bold {
						title-color();
						display: block;
						font-size: 19px;
						line-height: 25px;
						font-weight: bold;
						margin: 0 0 50px;
						text-align: center;
					}
				}
			}
		}
	}
	&__form {
		float: right;
		width: 505px;
		@media (max-width: 1200px) {
			box-sizing: border-box;
			float: none;
			margin: 60px 0 0;
			padding: 40px 6%;
			width: 100%;
		}
	}
	&.open {
		& {"^[-1]"}__overlay {
			transform: translateY(0);
		}
	}
}

@keyframes closePopup {
	0% {
		transform: rotate(0deg);
	}
	33% {
		transform: rotate(-30deg);
	}
	67% {
		transform: rotate(30deg);
	}
	100%{
		transform: rotate(0deg);
	}
}

@keyframes mouseScroll {
	50% {
		transform: scale(0.84);
	}
	100%{
		transform: scale(1);
	}
}

.fancybox-inner {
	margin: 0 auto;
	max-width: 800px;
}
